import { defineComponent, ref } from 'vue';
import router from '@/router';
import { useRoute, useRouter } from 'vue-router';
import { handleMenuDefaultActive } from '@/utils/map-menu';
export default defineComponent({
  components: {},
  props: {
    collapse: {
      type: Boolean,
      default: false
    },
    menuItem: {
      type: Object,
      required: true
    }
  },
  emits: ['menuChange'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var vueRouter = useRouter();
    var routes = useRoute();

    var handleSrc = function handleSrc(url) {
      router.push(url);
      vueRouter.beforeEach(function (to) {
        sendMenuChange(to.path);
      });
    };

    var activeIndex = ref('1-1');

    var sendMenuChange = function sendMenuChange(path) {
      var _handleMenuDefaultAct = handleMenuDefaultActive(path, props.menuItem),
          breadcrumb = _handleMenuDefaultAct.breadcrumb;

      emit('menuChange', breadcrumb);
    };

    var routePath = routes.path;

    var _handleMenuDefaultAct2 = handleMenuDefaultActive(routePath, props.menuItem),
        index = _handleMenuDefaultAct2.index;

    activeIndex.value = index;
    sendMenuChange(routePath);
    return {
      handleSrc: handleSrc,
      activeIndex: activeIndex,
      sendMenuChange: sendMenuChange
    };
  }
});