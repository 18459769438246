import { Menu as IconMenu, DataAnalysis, User } from '@element-plus/icons-vue'

import type { IMenuConfig } from '@/components/nav-menu/types/types'

import { handleMenuConfig } from '@/utils/map-menu'

const menuConfig: IMenuConfig[] = [
  {
    title: '商品管理',
    path: '/main/goods',
    icon: IconMenu,
    chidren: [
      {
        title: '商品列表',
        path: '/main/goods/goodList'
      },
      {
        title: '日期商品',
        path: '/main/goods/dateGoodList'
      }
    ]
  },
  {
    title: '订单管理',
    path: '/main/menu',
    icon: DataAnalysis,
    chidren: [
      {
        title: '可视化',
        path: '/main/order/chart'
      },
      {
        title: '订单列表',
        path: '/main/order/orderTable'
      }
    ]
  },
  {
    title: '人员管理',
    path: '/main/user',
    icon: User,
    chidren: [
      {
        title: '用户管理',
        path: '/main/user/wxuser'
      },
      {
        title: '管理员管理',
        path: '/main/user/admin'
      }
    ]
  }
]
handleMenuConfig(menuConfig)
export { menuConfig }
