import { defineComponent, ref } from 'vue';
import { Expand, Fold, UserFilled, CircleClose, ArrowDown } from '@element-plus/icons-vue';
import router from '@/router';
import localCache from '@/utils/cache';
export default defineComponent({
  components: {
    Expand: Expand,
    Fold: Fold,
    ArrowDown: ArrowDown,
    CircleClose: CircleClose
  },
  props: {
    breadcrumBitem: {
      type: Array,
      required: true
    }
  },
  emits: ['foldChange'],
  setup: function setup(prop, _ref) {
    var emit = _ref.emit;
    var isFold = ref(false);

    var foldHandleClick = function foldHandleClick() {
      isFold.value = !isFold.value;
      emit('foldChange', isFold.value);
    };

    var handleExit = function handleExit() {
      localCache.deleteCache('token');
      router.push('/main');
    };

    return {
      isFold: isFold,
      CircleClose: CircleClose,
      UserFilled: UserFilled,
      handleExit: handleExit,
      foldHandleClick: foldHandleClick
    };
  }
});