import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0af38cc9"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_nav_menu = _resolveComponent("nav-menu");

  var _component_el_aside = _resolveComponent("el-aside");

  var _component_nav_header = _resolveComponent("nav-header");

  var _component_el_header = _resolveComponent("el-header");

  var _component_router_view = _resolveComponent("router-view");

  var _component_el_main = _resolveComponent("el-main");

  var _component_el_container = _resolveComponent("el-container");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, {
    class: "main-container"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_aside, {
        width: _ctx.isCollapse ? '60px' : '210px'
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_nav_menu, {
            collapse: _ctx.isCollapse,
            menuItem: _ctx.menuConfig,
            onMenuChange: _ctx.menuChange
          }, null, 8, ["collapse", "menuItem", "onMenuChange"])];
        }),
        _: 1
      }, 8, ["width"]), _createVNode(_component_el_container, {
        class: "page"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_header, {
            class: "page-header",
            breadcrumb: _ctx.breadcrumb,
            menuItem: _ctx.menuConfig
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_nav_header, {
                onFoldChange: _ctx.handleFoldChange,
                breadcrumBitem: _ctx.breadcrumBitem
              }, null, 8, ["onFoldChange", "breadcrumBitem"])];
            }),
            _: 1
          }, 8, ["breadcrumb", "menuItem"]), _createVNode(_component_el_main, {
            class: "page-content"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_router_view, null, {
                default: _withCtx(function (_ref) {
                  var Component = _ref.Component;
                  return [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))];
                }),
                _: 1
              })];
            }),
            _: 1
          })];
        }),
        _: 1
      })];
    }),
    _: 1
  })]);
}